// import axios from "axios";

// const baseUrl = axios.create({
//   baseURL: "http://localhost:8080",
// });
// export default baseUrl;

// const baseUrl = 'http://localhost:8080';
// export default baseUrl;

// const baseUrl = 'https://testtomcat.vsmartengine.com/elearning';
// export default baseUrl;

// const baseUrl = 'https://testtomcat.vsmartengine.com/eLearning';
// export default baseUrl;

const baseUrl = 'https://lhdemotomcat.vsmartengine.com/eLearning';
export default baseUrl;


